<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 



			<div class="row" style="padding-top:40px;">

				<div class="col-md-6">


			<div style="padding-top: 40px;">

				<router-link  class="btn p-0 color-1" to="/home"> <i class="bi bi-chevron-left"></i> Back </router-link>

				<h4>Join Chat</h4>

				<img src="/assets/images/Join chat.gif" style="width: 100px;">



				<div style="padding-top: 10px;">


					<div v-if="this.$store.state.am_in_the_waiting_room=='yes'" class="custom-message" style="text-align:center;">
					<h1 class="color-1"> <i class="bi bi-info-circle"></i> </h1>
					<p>You cannot join chat because you have a topic in the waiting page</p>
					<router-link to="/waiting-room" class="btn btn-1">Waiting room</router-link>
				</div>


				<div v-if="this.$store.state.am_in_klub_waiting_room=='yes'" class="custom-message" style="text-align:center;">
					<h1 class="color-1"> <i class="bi bi-info-circle"></i> </h1>
					<p>You cannot join chat because you have a topic in the klub waiting page</p>
					<router-link to="/klub-waiting-room" class="btn btn-1">Waiting room</router-link>
				</div>




					
					<div v-if="this.$store.state.am_in_the_waiting_room!='yes' && this.$store.state.am_in_klub_waiting_room!='yes' ">



					<div style="min-height: 60px;">
						
						<div v-if="topics2.length>0">
			<button @click="pick_topic(topics_to_display['topicHash'])" class="btn topic-btn "> 

			<div class="row">
				 <div class="col-md-12">
				 	<span>{{topics_to_display['topicName']}}</span>
				 </div>
				 
			</div>

			 </button>
			</div>

		

				<div v-if="topics2.length==0 && show_loader==false"> No topics available at the moment </div>


					</div>					
			


					<div style="padding-top:10px;">
						<button @click="previous_topic()" class="btn btn-2" style="margin-right:10px;"> <i class="bi bi-chevron-left"></i> Previous</button>
					<button @click="next_topic()" class="btn btn-1">Next <i class="bi bi-chevron-right"></i> </button>
					</div>

					</div>

				</div>



				
			</div>
						

				</div>



				<div class="col-md-6">
					
				</div>

				
			</div>

	

		

			
		</div> 
	</div> 
	


</template>

<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import { io } from "socket.io-client"
	import axios from 'axios'


	export default{
		name : 'home',
		components : { SideBar, TopBar },
		data(){
			return{
				socket : io(this.$store.state.socketio_server),
				topics : [],
				friends : [],
				topics2 : [],
				topics_to_display : [],
				index : 0,
				show_loader : true
			}
		},

		methods : {


			previous_topic(){
				if(this.topics2.length>0 && this.index>=1){
					this.index--
				}

				this.topics_to_display = this.topics2[this.index]
			},

			next_topic(){
				if(this.topics2.length>0 && this.index<this.topics2.length-1){
					this.index++
				}

				this.topics_to_display = this.topics2[this.index]
			},


			async get_friends(){
				const res = await axios.get(this.$store.state.url+'get-my-friends/'+this.$store.state.user_id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
			this.friends = res 

		

			this.topics.forEach((data)=>{
				res.forEach((data2)=>{
					if(data.userId==data2.friend){
						this.topics2 = this.topics2.concat(data)
					}
				})
			})



			//topic to display
  				if(this.topics2.length>0){
  					this.topics_to_display = this.topics2[this.index]
  				}

  				this.show_loader = false

			


			},

			pick_topic(topic_hash){

				this.topic_details(topic_hash)

			this.socket.emit('click klub topic', { 'room' : topic_hash,'clicked_topic_hash' : topic_hash, 'user_id' : this.$store.state.user_id })	
			},

			async topic_details(topic_hash){
				const res = await axios.get(this.$store.state.url+'topic-details/'+topic_hash).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.$store.state.current_topic_hash = topic_hash
				localStorage.setItem('current_topic_hash',topic_hash)

				res.forEach((data)=>{
				this.$store.state.current_topic_name = data.topicName
				localStorage.setItem('current_topic_name',data.topicName)

				})

				localStorage.setItem('chatting_in_progress','yes')
				this.$store.state.chatting_in_progress = 'yes'

					localStorage.setItem('distance2','')
				this.$store.state.distance2 = ''


				this.$router.push('/klub-chat')
			}

		},

		created(){


			//broadcast to fetch topics
  				this.socket.emit('fetch klub chat topics', { 'room' : 'klub waiting room', 'user_id' : this.$store.state.user_id })

  				//get fetch topics
  				this.socket.on('klub topics',(data)=>{
  					this.topics = data
  					this.get_friends()

  				})

  				//get  topics after a topic has been clicked
  				this.socket.on('klub topics after clicking',(data)=>{
  					this.topics = data
  					this.get_friends()
  				
  				})

  			

		}




	}
</script>

