<template>



		<div style=" width: 100%; right: 0; z-index: 1200; background-color: #1a1a1a; padding-top: 20px; padding-bottom: 20px; position: sticky; top: 0; ">
			
			<div class="container">


			<div class="row">

			<div class="col-md-4 col-4">

			 <button  class="btn btn-2 shadow-lg hide-on-desktop" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev" style="margin-right:10px; min-width: 120px;">
   <i class="bi bi-chevron-left"></i> Previous
  </button>

  <button  class="btn btn-2 shadow-lg hide-on-phone" type="button" data-bs-target="#desktopcarouselExampleIndicators" data-bs-slide="prev" style="margin-right:10px; min-width: 120px;">
   <i class="bi bi-chevron-left"></i> Previous
  </button>	

			</div>

			<div class="col-md-4 col-4"> 

				<div style="text-align:center;">
					<img src="/assets/images/logo.png" style="height:40px;">
			</div>

			</div>

		<div class="col-md-4 col-4" style="text-align:right;">
			
			 <button style="min-width: 120px;" class="btn btn-2 shadow-lg hide-on-desktop" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next"> Next 
    <i class="bi bi-chevron-right"></i> 
  </button>


  <button style="min-width: 120px;" class="btn btn-2 shadow-lg hide-on-phone" type="button" data-bs-target="#desktopcarouselExampleIndicators" data-bs-slide="next"> Next 
    <i class="bi bi-chevron-right"></i> 
  </button>

		</div>
 
</div>
				
			</div>

		</div>









<!-- PHONE VERSION -->
	
	<section style="padding-top:0px; padding-bottom: 50px; background-image: linear-gradient(to top,#f75318,#1a1a1a); height: 150vh;" class="hide-on-desktop">
		

		<div class="container" style="padding-top:50px;">

			
		<div class="row">
				<div class="col-md-2"></div>

				<div class="col-md-8">

					<div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">


  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
     <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
     <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6"></button>
     <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="6" aria-label="Slide 7"></button>
     <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="7" aria-label="Slide 8"></button>
     <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="8" aria-label="Slide 9"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="9" aria-label="Slide 10"></button>
       <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="10" aria-label="Slide 11"></button>

  </div>


  <div class="carousel-inner">
    <div class="carousel-item active">
    	<h6 class="color-1">Welcome to Maifes</h6>
      <img src="assets/images/phone-tuts/Chatting Intro Screen.png" class="d-block w-100 shadow-lg animate__animated animate__pulse" alt="Maifes">
    </div>

    <div class="carousel-item" >
    	<h6 class="color-1">Create a topic</h6>
      <img src="assets/images/phone-tuts/Chatting Screen One.png" class="d-block w-100 shadow-lg animate__animated animate__pulse" alt="Maifes">
    </div>


    <div class="carousel-item">
    	<h6 class="color-1">Wait someone to join chat</h6>
      <img src="assets/images/phone-tuts/Chatting Screen One-1.png" class="d-block w-100 shadow-lg animate__animated animate__pulse" alt="Maifes">
    </div>


     <div class="carousel-item">
    	<h6 class="color-1">Join chat</h6>
      <img src="assets/images/phone-tuts/Join Chat Mobile (1).png" class="d-block w-100 shadow-lg animate__animated animate__pulse" alt="Maifes">
    </div>


    <div class="carousel-item">
     	<h6 class="color-1">Chatroom</h6>
      <img src="assets/images/phone-tuts/Chatroom Intro Mobile.png" class="d-block w-100 shadow-lg animate__animated animate__pulse" alt="Maifes">
    </div>


     <div class="carousel-item">
     	<h6 class="color-1">Chat with someone</h6>
      <img src="assets/images/phone-tuts/chatroom 1 Mobile.png" class="d-block w-100 shadow-lg animate__animated animate__pulse" alt="Maifes">
    </div>


     <div class="carousel-item">
     	<h6 class="color-1">Add friend to klub</h6>
      <img src="assets/images/phone-tuts/Add friend to Klub.png" class="d-block w-100 shadow-lg animate__animated animate__pulse" alt="Maifes">
    </div>


      <div class="carousel-item">
     	<h6 class="color-1">View posts from members of your klub</h6>
      <img src="assets/images/phone-tuts/klub.png" class="d-block w-100 shadow-lg animate__animated animate__pulse" alt="Maifes">
    </div>


      <div class="carousel-item">
     	<h6 class="color-1">Update backgrounds</h6>
      <img src="assets/images/phone-tuts/backgrounds.png" class="d-block w-100 shadow-lg animate__animated animate__pulse" alt="Maifes">
    </div>



      <div class="carousel-item">
     	<h6 class="color-1">Notifications</h6>
      <img src="assets/images/phone-tuts/Notification Into Screen.png" class="d-block w-100 shadow-lg animate__animated animate__pulse" alt="Maifes">
    </div>



     <div class="carousel-item">
     	<h6 class="color-1">View notifications</h6>
      <img src="assets/images/phone-tuts/Notifications Mobile.png" class="d-block w-100 shadow-lg animate__animated animate__pulse" alt="Maifes">
    </div>


  </div>


</div>

		
				</div>


			</div>

		</div>


	</section>

<!-- PHONE VERSION -->















<!-- DESKTOP VERSION -->
	
	<section style="padding-top:0px; padding-bottom: 50px; background-image: linear-gradient(to top,#f75318,#1a1a1a); height: 150vh;" class="hide-on-phone">
		

		<div class="container" style="padding-top:50px;">

			
		<div class="row">
				<div class="col-md-2"></div>

				<div class="col-md-8">

					<div id="desktopcarouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">

  <div class="carousel-indicators">
    <button type="button" data-bs-target="#desktopcarouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#desktopcarouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#desktopcarouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#desktopcarouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
     <button type="button" data-bs-target="#desktopcarouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
     <button type="button" data-bs-target="#desktopcarouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6"></button>
     <button type="button" data-bs-target="#desktopcarouselExampleIndicators" data-bs-slide-to="6" aria-label="Slide 7"></button>
     <button type="button" data-bs-target="#desktopcarouselExampleIndicators" data-bs-slide-to="7" aria-label="Slide 8"></button>
     <button type="button" data-bs-target="#desktopcarouselExampleIndicators" data-bs-slide-to="8" aria-label="Slide 9"></button>
      <button type="button" data-bs-target="#desktopcarouselExampleIndicators" data-bs-slide-to="9" aria-label="Slide 10"></button>
       <button type="button" data-bs-target="#desktopcarouselExampleIndicators" data-bs-slide-to="10" aria-label="Slide 11"></button>

  </div>

  <div class="carousel-inner">
    <div class="carousel-item active">
    	<h6 class="color-1">Welcome to Maifes</h6>
      <img src="assets/images/tuts/Chatting.jpg" class="d-block w-100 shadow-lg animate__animated animate__pulse" alt="Maifes">
    </div>

    <div class="carousel-item" >
    	<h6 class="color-1">Create a topic</h6>
      <img src="assets/images/tuts/Topics (1).jpg" class="d-block w-100 shadow-lg animate__animated animate__pulse" alt="Maifes">
    </div>


    <div class="carousel-item">
    	<h6 class="color-1">Wait someone to join chat</h6>
      <img src="assets/images/tuts/Waiting Desktop.png" class="d-block w-100 shadow-lg animate__animated animate__pulse" alt="Maifes">
    </div>


     <div class="carousel-item">
    	<h6 class="color-1">Join chat</h6>
      <img src="assets/images/tuts/join chat.jpeg" class="d-block w-100 shadow-lg animate__animated animate__pulse" alt="Maifes">
    </div>


    <div class="carousel-item">
     	<h6 class="color-1">Chatroom</h6>
      <img src="assets/images/tuts/Chatroom.jpg" class="d-block w-100 shadow-lg animate__animated animate__pulse" alt="Maifes">
    </div>


     <div class="carousel-item">
     	<h6 class="color-1">Chat with someone</h6>
      <img src="assets/images/tuts/Chatroom 1.jpg" class="d-block w-100 shadow-lg animate__animated animate__pulse" alt="Maifes">
    </div>


     <div class="carousel-item">
     	<h6 class="color-1">Add friend to klub</h6>
      <img src="assets/images/tuts/Add friend to klub.jpg" class="d-block w-100 shadow-lg animate__animated animate__pulse" alt="Maifes">
    </div>


      <div class="carousel-item">
     	<h6 class="color-1">View posts from members of your klub</h6>
      <img src="assets/images/tuts/Klub.jpg" class="d-block w-100 shadow-lg animate__animated animate__pulse" alt="Maifes">
    </div>


      <div class="carousel-item">
     	<h6 class="color-1">Update backgrounds</h6>
      <img src="assets/images/tuts/backgrounds.jpg" class="d-block w-100 shadow-lg animate__animated animate__pulse" alt="Maifes">
    </div>



      <div class="carousel-item">
     	<h6 class="color-1">Notifications</h6>
      <img src="assets/images/tuts/Notification.jpg" class="d-block w-100 shadow-lg animate__animated animate__pulse" alt="Maifes">
    </div>



     <div class="carousel-item">
     	<h6 class="color-1">View notifications</h6>
      <img src="assets/images/tuts/Notification 2.jpg" class="d-block w-100 shadow-lg animate__animated animate__pulse" alt="Maifes">
    </div>


  </div>


</div>

		
				</div>


			</div>

		</div>


	</section>

<!-- DESKTOP VERSION -->






<div style="position:fixed; right: 0; bottom: 0; padding-right: 30px; padding-bottom: 30px; ">
	
	<router-link to="/home" class="btn btn-1 shadow-lg">Skip Tour <i class="bi bi-chevron-right"></i> </router-link>

</div>







</template>


<script>
	
	import 'animate.css'

	export default{
		name : 'tuts'
	}

</script>

