<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 



			<div class="row" style="padding-top:40px;">

				<div class="col-md-6">


			<div style="padding-top: 40px;">

				<router-link  class="btn p-0 color-1" to="/home"> <i class="bi bi-chevron-left"></i> Back </router-link>

				<h4>History</h4>

				<p class="text-info" v-if="message">{{message}}</p>


				<div>
					
				<div v-for="t in topics">
						<button :disabled="this.$store.state.am_in_the_waiting_room=='yes' || this.$store.state.am_in_klub_waiting_room=='yes' " data-bs-toggle="modal" data-bs-target="#exampleModal" @click="topic_details(t.topicHash,t.id)" class="btn p-2 mt-3 border-bottom trending-topic-btn animate__animated animate__fadeInUp animate__slow"> <i class="bi bi-check color-1"></i> {{t.topicName}} </button>
					</div>


				</div>

				
			</div>
						

				</div>



				<div class="col-md-6">
					
				</div>

				
			</div>

	

		

			
		</div> 
	</div> 




	<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">  Chat on Maifes</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
       
      <div style="text-align:center;">
      	 <h6> {{topic_name}} </h6>

      	 <div>
      	 	<button data-bs-dismiss="modal" @click="create_from_history()" class="btn btn-2" style="margin-right: 10px;">Create chat</button>
      	 	<button class="btn btn-1" data-bs-dismiss="modal" @click="delete_topic()">Delete topic</button>
      	 </div>

      </div>

      </div>
      
    </div>
  </div>
</div>
	


</template>

<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from 'axios'
	import 'animate.css'
	export default{
		name : 'home',
		components : { SideBar, TopBar },
		data(){
			return{
				topics : [],
				message : '',
				clicked_topic : '',
				topic_name : '',
				topic_id : '',
				
			}
		},

		methods : {

			create_from_history(){
				this.create_topic()
			},

			create_topic(){
				if(!this.topic_name){
					return
				}

				localStorage.setItem('current_topic_name',this.topic_name)
				this.$store.state.current_topic_name = this.topic_name
				let hash = Date.now()
				localStorage.setItem('current_topic_hash',hash)
				this.$store.state.current_topic_hash = hash

				this.create_topic_now()

						localStorage.setItem('am_in_the_waiting_room','yes')
				this.$store.state.am_in_the_waiting_room = 'yes'

				this.$router.push('/waiting-room')

			},


			async create_topic_now(){
				const res = await axios.post(this.$store.state.url+"create-topic",{
					user_id : this.$store.state.user_id,
					topic_hash : this.$store.state.current_topic_hash,
					topic_name : this.$store.state.current_topic_name,
					klub : 0
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				
				if(res=='success'){
					this.message = 'Topic added to your library!'
				}
				 
			},

			topic_details(topic_hash,id){
				this.topic_id = id 
				this.clicked_topic = topic_hash

				this.topics.forEach((data)=>{
					if(data.topicHash==topic_hash){
						this.topic_name = data.topicName
					}
				})
			},

			async delete_topic(){
			const res = await axios.post(this.$store.state.url+'delete-topic-history',{
				id : this.topic_id
			}).then(function(response){
				return response.data
			}).catch(function(error){
				console.log(error)
			})
			this.get_topics()
			},

			async get_topics(){
				const res = await axios.get(this.$store.state.url+'topic-history/'+this.$store.state.user_id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.topics = res 
				if(res.length==0){
					this.message = 'No topics found'
				}
			}

		},

		created(){
			this.get_topics()

				localStorage.setItem('distance2',180000)
			this.$store.state.distance2 = 180000


			localStorage.setItem('chatting_in_progress','no')
			this.$store.state.chatting_in_progress = 'no'

		}

	}
</script>

