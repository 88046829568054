<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 



			<div class="row">

				<div class="col-md-6" style="padding-top:40px;">


			<div style="padding-top: 0px;" class="shadow-lg rounded">

				<div style="padding-bottom:0px;">
					
					<div class="row">

						<div class="col-md-6 col-6">
							<router-link  class="btn p-0 color-1" to="/home"> <i class="bi bi-chevron-left"></i> Back </router-link>
						</div>

						<div class="col-md-6 col-6" style="text-align:right;">

							<button class="btn" @click="like_post()"> <i :class=" { 'bi bi-heart-fill color-1' : post_liked==true, 'bi bi-heart' : post_liked==false } "></i> </button>
							<router-link to="/home" class="btn btn-lg text-danger"> <i class="bi bi-x"></i> </router-link>
						</div>
						
					</div>


					<div style="border-top:solid 1px #ddd;"></div>

				</div>
				<div style="border-top: solid 1px #fff; margin-bottom: 5px;"></div>



				<div style="padding-left:5px;">
					<h5>{{title}}</h5>
				</div>

				<div :style=" { backgroundImage : 'url('+background+')' } " style="min-height: 300px; background-size: cover; background-position: center; ">
					
						<!--messages section-->
		<div style="overflow: scroll; height: 350px; padding-top: 30px; padding-left: 30px; padding-right: 30px; padding-bottom: 60px; ">

			<div v-for="c in comments" >
				
				<!--messages section-->
			
<div style="padding-top:10px; text-align: left;">
	
	<button  style="background-color:#001405; color:#fff; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; border-top-left-radius:20px; text-align:left; border:solid 1px lightgrey; min-width:100px; " class="btn shadow-lg"> {{c.message}} 

		<div style="text-align:right; font-size: 12px;">
			<small> <i class="bi bi-heart"></i> {{c.total_likes}} likes   </small>
		</div>

	</button>

</div>


			</div>



<div ref="bottomEl"></div>
</div>


				</div>



				<!--form section-->
<div style="padding: 10px; border-bottom-left-radius:10px; border-bottom-right-radius:10px; background-color: #f5f5f5; ">


<div class="input-group">
  <input  @input="get_characters()" @keyup.enter="post_comment()" type="text" class="form-control" placeholder="Type a message" aria-label="Type a message" aria-describedby="button-addon2" style="border: none; border-radius: 5px;" v-model="message">

  <button :disabled="disabled" @click="post_comment()" class="btn" type="button" id="button-addon2"> <i class="bi bi-chevron-right"></i> </button>
</div>


	<div class="mt-1">
	<label style="border:solid 2px #f75318; padding: 6px; border-radius: 50px; color: #f75318; height: 30px; width: 30px; text-align: center; font-size:10px; "> {{character_limit}} </label>
</div>

</div>

				
			</div>
						

				</div>



				<div class="col-md-6">
				
				</div>

				
			</div>

	

		

			
		</div> 
	</div> 
	


</template>

<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from 'axios'
	import { io } from "socket.io-client"

	export default{
		name : 'home',
		components : { SideBar, TopBar },
		data(){
			return{
				details : [],
				id : '',
				title : '',
				socket : io(this.$store.state.socketio_server),
				message : '',
				post_owner : '',
				comments : [],
				post_liked : false,
				background : '',
				character_limit : 500,
				disabled : false

			}
		},

		methods : {


			get_characters(){
				let c = this.message.length
				this.character_limit = 500 - c
				if(this.character_limit<=0){
					this.character_limit = 0
					this.disabled = true
					
				}

				if(this.character_limit>0){
					this.disabled = false
				}

			},

			like_post(){
				this.socket.emit('like post', { 'user_id' : this.$store.state.user_id, 'post_owner' : this.post_owner, 'post_id' : this.id  } )
				this.check_post_like()

			},

			async check_post_like(){
				const res = await axios.get(this.$store.state.url+'check-post-like/'+this.id+'/'+this.$store.state.user_id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				if(res.length>0){
					this.post_liked = true
				}else{
					this.post_liked = false
				}
			},

			async post_comments(){
				const res = await axios.get(this.$store.state.url+'post-comments/'+this.id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.comments = res 
				this.$refs.bottomEl?.scrollIntoView({ behavior: 'smooth' });
			},

			post_comment(){

				if(!this.message){
					return
				}


				if(this.character_limit<=0){
					return
				}



				this.socket.emit('post comment', { 'user_id' : this.$store.state.user_id, 'room' : this.id, 'post_owner' : this.post_owner, 'message' : this.message } )
				this.message = ''
				this.post_comments()
				this.character_limit = 500
			},


			async post_details(){
				const res = await axios.get(this.$store.state.url+'post-details/'+this.id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.details = res
				res.forEach((data)=>{
					this.title = data.postTitle
					this.post_owner = data.userId
				}) 
			}
		},

		created(){
			this.id = this.$route.params.post_id 
			this.post_details()
			this.post_comments()

			this.socket.on('new post comment',()=>{
				this.post_comments()
			})


			this.check_post_like()

		},

		mounted(){
			//scroll chat div to top automatically
			setTimeout(()=>{
				this.$refs.bottomEl?.scrollIntoView();
			},100)


				if(this.$store.state.background){
				this.background = this.$store.state.url+'backgrounds/'+this.$store.state.background
			}else{
				this.background = '/assets/images/360_F_77634979_e2tCyG0BBFOAqA5Sd580GT5IH1Uw1JzV.jpeg'
			}

			
		}

	}
</script>

