<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 



			<div class="row" style="padding-top:40px;">

				<div class="col-md-6">


			<div style="padding-top: 40px;">

				<router-link  class="btn p-0 color-1" to="/home"> <i class="bi bi-chevron-left"></i> Back </router-link>

				<h4>Profile</h4>


				<div>
					<button class="btn border-bottom" style="margin-right:10px;"> Klub <b class="color-1">{{friends.length}}</b> member(s)  </button>
					<!--<button class="btn border-bottom"> <b>{{friends.length}}</b> Following </button>-->
				</div>


				<div>

					<p class="text-info"> {{message}} </p>
					
				<form @submit.prevent="change_profile()" method="post">
					
					<div class="row">
						<div class="col-md-6 mt-2">
							<label>Email</label>
							<input v-model="email" disabled type="email" placeholder="Email address" class="form-control">
						</div>

						<div class="col-md-6 mt-2">
							<label>Phone</label>
							<input v-model="phone" type="text" placeholder="Phone number" class="form-control">
						</div>

						<div class="col-md-12 mt-2">
							<label>Password</label>
							<input type="password" placeholder="********" class="form-control" v-model="password">
						</div>


						<div class="col-md-12 mt-3">
							<button class="btn btn-1">Save changes</button>
						</div>

					</div>

				</form>

				</div>




			<div style="padding-top:30px;">
				
				<h4>Backgrounds</h4>


				<div class="row mt-1">

					<div class="col-md-4 col-3 mt-3" v-for="b in backgrounds">
						<div :style=" { backgroundImage : 'url('+this.$store.state.url+'backgrounds/'+b.file+')', width : '100%', height : '100px', backgroundPosition : 'center', backgroundSize : 'cover', borderRadius : '10px' }" class="shadow-lg custom-bg" @click="get_image(b.image,b.file)" > <i v-if="b.file==file" class="bi bi-check-circle m-1 color-1"></i> </div>
						
					</div>


			
					
				</div>


				<div class="mt-3">
					

					<button @click="set_background()" class="btn btn-outline-warning">Save changes</button>
					
				</div>

			</div>



				
			</div>
						

				</div>



				<div class="col-md-6">
					
				</div>

				
			</div>

	

		

			
		</div> 
	</div> 
	


</template>

<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from 'axios'

	export default{
		name : 'home',
		components : { SideBar, TopBar },
		data(){
			return{
				email : '',
				phone : '',
				password : '',
				message : '',
				id : '',
				friends : [],
				backgrounds : [],
				image : '',
				file : ''
			}
		},

		methods : {

			get_image(image,file){
				this.image = image
				this.file = file

			},


			async set_background(){
				if(!this.file){
					return
				}
				const res = await axios.post(this.$store.state.url+'set-background',{
					background : this.file,
					user_id : this.$store.state.user_id 
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.message = 'Changes saved'
				this.$store.state.background = this.file
				localStorage.setItem('background',this.file)

			},



			async get_backgrounds(){
				const res = await axios.get(this.$store.state.url+'get-backgrounds').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
			this.backgrounds = res 

			},



			async get_friends(){
				const res = await axios.get(this.$store.state.url+'get-my-friends/'+this.$store.state.user_id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
			this.friends = res 


			},

					async change_profile(){

				if(!this.email){
					this.message = 'Email cannot be blank'
					return
				}

				if(!this.phone){
					this.message = 'Phone cannot be blank'
					return
				}


				const res = await axios.post(this.$store.state.url+'change-profile',{
					user_id : this.$store.state.user_id,
					email : this.email,
					phone : this.phone,
					password : this.password
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				if(res=='success'){
					this.message = 'Changes saved successfully'
					localStorage.setItem('email',this.email)
					localStorage.setItem('phone',this.phone)
				}else{
					this.message = 'Something went wrong!'
				}

			}

		},
		created(){
			this.id = localStorage.getItem('id')
			this.email = localStorage.getItem('email')
			this.phone = localStorage.getItem('phone')
			this.get_friends()
			this.get_backgrounds()

			this.file = this.$store.state.background
		}

	}
</script>

