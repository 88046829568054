<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 



			<div class="row" style="padding-top:40px;">

				<div class="col-md-6">


			<div style="padding-top: 40px;">

				<router-link  class="btn p-0 color-1" to="/home"> <i class="bi bi-chevron-left"></i> Back </router-link>

				<h4>Notifications</h4>


				<div>
					<h6>Likes</h6>
					
		<router-link v-for="p in posts" :to=" { name : 'view-post', params : { post_id : p.post_id } } " class="custom-message w-100 btn mt-3 animate__animated animate__fadeInUp animate__slow" style="text-align: left;">
		<div class="row">
			<div class="col-md-1 col-2"><i class="bi bi-bell color-1"></i></div>
			<div class="col-md-10 col-10"> Someone liked <b>{{p.post_title}}</b> </div>
		</div></router-link>

		<p v-if="posts.length==0"> <i class="bi bi-info-circle color-1"></i> No likes</p>
				
				</div>

				<div style="border-top: solid 1px lightgrey; margin-top: 50px; margin-bottom: 40px;"></div>


				<div>

					<h6>Comments</h6>
					
		<router-link v-for="c in comments" :to=" { name : 'view-comment', params : { id : c.id } } " class="custom-message w-100 btn mt-3 animate__animated animate__fadeInUp animate__slow" style="text-align: left;">
		<div class="row">
			<div class="col-md-1 col-2"><i class="bi bi-bell color-1"></i></div>
			<div class="col-md-11 col-10"> Someone commented  on <b>{{c.post_title}}</b> <br />


			<div class="row">
				<div class="col-md-1 col-2">
				<i class="bi bi-person-circle color-1"></i>	
				</div>
				<div class="col-md-11 col-10">
				{{c.message}}	
				</div>
			</div>

		</div>
		</div></router-link>

		<p v-if="comments.length==0"> <i class="bi bi-info-circle color-1"></i> No comments</p>
				
				</div>



				<div style="border-top: solid 1px lightgrey; margin-top: 50px; margin-bottom: 40px;"></div>



				<div>

					<h6>Replies</h6>
					
		<router-link v-for="r in replies" :to=" { name : 'view-post', params : { post_id : r.post_id } } " class="custom-message w-100 btn mt-3 animate__animated animate__fadeInUp animate__slow" style="text-align: left;">
		<div class="row">
			<div class="col-md-1 col-2"><i class="bi bi-bell color-1"></i></div>
			<div class="col-md-10 col-10"> Someone replied <b>{{r.message}}</b> on your comment <b>@{{r.comment}}</b> on <b>{{r.post_title}}</b> </div>
		</div></router-link>


			<p v-if="replies.length==0"> <i class="bi bi-info-circle color-1"></i> No replies</p>

				</div>

				
			</div>
						

				</div>



				<div class="col-md-6">

				</div>

				
			</div>

	

		

			
		</div> 
	</div> 
	


</template>

<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from 'axios'
	import { io } from "socket.io-client"

	export default{
		name : 'home',
		components : { SideBar, TopBar },
		data(){
			return{
				posts : [],
				comments : [],
				socket : io(this.$store.state.socketio_server),
				replies : []
			}
		},

		methods : {

			async replies_on_my_comments(){
				const res = await axios.get(this.$store.state.url+'replies-on-my-comments/'+this.$store.state.user_id).then(function(response){
					return response.data
				}).catch(function(erorr){
					console.log(error)
				})
				this.replies = res  
			},

			async post_likes(){
				const res = await axios.get(this.$store.state.url+'my-post-likes/'+this.$store.state.user_id).then(function(response){
					return response.data
				}).catch(function(erorr){
					console.log(error)
				})
				this.posts = res 
			},

			async post_comments(){
				const res = await axios.get(this.$store.state.url+'my-post-comments/'+this.$store.state.user_id).then(function(response){
					return response.data
				}).catch(function(erorr){
					console.log(error)
				})
				this.comments = res 
			}
		},

		created(){
			localStorage.setItem('post_liked','false')
			this.$store.state.post_liked = 'false'
			localStorage.setItem('new_post_comment','false')
			this.$store.state.new_post_comment = 'false'
			localStorage.setItem('new_comment_reply','false')
			this.$store.state.new_comment_reply = 'false'
			this.post_likes()
			this.post_comments()
			this.replies_on_my_comments()


			this.socket.on('new post comment',(data)=>{
				if(data.post_owner==this.$store.state.user_id){
					this.post_comments()
				}
			})


			this.socket.on('reply received',(data)=>{
				if(data.comment_owner==this.$store.state.user_id){
					this.replies_on_my_comments()
				}
			})
		}

	}
</script>

