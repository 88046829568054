<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 



			<div class="row" style="padding-top:40px;">

				<div class="col-md-6">
				

			<div style="padding-top:30px;">

				<router-link  class="btn p-0 color-1" to="/home"> <i class="bi bi-chevron-left"></i> Back </router-link>


				<div class="row mb-3">

					<div class="col-md-6 col-6">
						<h4>New Post</h4>
					</div>

					<div class="col-md-6 col-6" style="text-align:right;">
						<router-link to="/join-chat" class="btn btn-outline-warning">Join Chat <i class="bi bi-plus"></i> </router-link>
					</div>
					
				</div>
				
				<p v-if="message" :class="text_class">{{message}}</p>

				<textarea @input="get_characters()" v-model="post_title" placeholder="What's on your mind?" class="form-control" rows="6"></textarea>

					<div class="row">
					<div class="col-md-6 col-6">
						<button :disabled="disabled"  @click="new_post()" class="btn btn-1 mt-2"> <i class="bi bi-plus"></i> Create</button>
					</div>

					<div class="col-md-6 col-6 p-2" style="text-align:right;">
						<button v-if="post_title" @click="clear()" class="btn color-3"> <i class="bi bi-x"></i> Clear</button>
						<label style="border:solid 2px #f75318; padding: 6px; border-radius: 50px; color: #f75318; height: 40px; width: 40px; text-align: center; "> {{character_limit}} </label>
					</div>

				</div>


			</div>

				<div style="padding-top: 40px;">

				<h4>My Posts</h4>

				<div>

					<div v-for="p in posts" style="background-color:#fff;" class="rounded mt-3 shadow">
					
						<div class="row">
							<div class="col-md-8 col-9">
								 <div class="p-2">
								 	<p>{{p.post_title}} </p>
								 </div>
							</div>

							<div class="col-md-4 col-3" style="text-align:right;">
									<label class="dropdown">
  <button class="btn dropdown-toggl" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" style="border: none;">
    <i class="bi bi-three-dots-vertical"></i>
  </button>
  <ul class="dropdown-menu shadow-lg" aria-labelledby="dropdownMenuButton1" style="border: none;">
    <li> <button data-bs-toggle="modal" data-bs-target="#exampleModal" @click="post_details(p.post_id)" class="btn dropdown-item">Edit</button>  </li>
    <li> <button @click="delete_post(p.post_id)" class="btn dropdown-item">Delete</button> </li>
  </ul>
</label>
							</div>
						</div>

					<div style="border-top: solid 1px lightgrey; margin-bottom: 5px;"></div>

					<div class="row">
						<div class="col-md-6 col-6">
						<router-link :to=" { name : 'view-post', params : { post_id : p.post_id } } " class="btn"><i class="bi bi-eye"></i> </router-link>	
						</div>
						<div class="col-md-6 col-6">
						<div style="text-align:right;">
						<button class="btn"><i class="bi bi-heart"></i> {{p.total_likes}} likes </button>
					</div>	
						</div>
					</div>

					</div>
					

				</div>

				
			</div>


				</div>



				<div class="col-md-6">

					
				</div>

				
			</div>

	

		

			
		</div> 
	</div> 



	<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">  Chat on Maifes</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
       
     	<textarea v-model="post_title_2" placeholder="What's on your mind?" class="form-control" rows="6"></textarea>

				<button data-bs-dismiss="modal" @click="edit_post()"  class="btn btn-1 mt-2">  Save changes</button>

      </div>
      
    </div>
  </div>
</div>
	


</template>

<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from 'axios'
	

	export default{
		name : 'home',
		components : { SideBar, TopBar },
		data(){
			return{
				post_title : '',
				message : '',
				text_class  : '',
				posts : [],
				post_id : '',
				post_title_2 : '',
				character_limit : 300,
				disabled : false
			}
		},

		methods : {

			clear(){
				this.post_title = ''
				this.character_limit = 300
			},


			get_characters(){
				let c = this.post_title.length
				this.character_limit = 300 - c
				if(this.character_limit<=0){
					this.character_limit = 0
					this.disabled = true
					
				}

				if(this.character_limit>0){
					this.disabled = false
				}

			},

			async edit_post(){
				if(!this.post_title_2){
					return
				}

				const res = await axios.post(this.$store.state.url+'edit-post',{
					post_id : this.post_id,
					post_title : this.post_title_2
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.get_posts()
				 
			},


			async post_details(post_id){
				this.post_id = post_id
				const res = await axios.get(this.$store.state.url+'post-details/'+post_id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				res.forEach((data)=>{
					this.post_title_2 = data.postTitle
				})
				 
			},

			async delete_post(id){
				const res = await axios.post(this.$store.state.url+'delete-post',{
					post_id : id
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.get_posts()
			},


			async new_post(){
				if(!this.post_title){
					this.text_class = 'text-danger'
					this.message = 'Enter post title'
					return
				}

				const res = await axios.post(this.$store.state.url+'new-post',{
					post_title : this.post_title,
					user_id : this.$store.state.user_id
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				if(res=='success'){
					this.text_class = 'text-success'
					this.message = 'Post created successfully!'
				}
				this.get_posts()
				this.post_title = ''
			},

			async get_posts(){
				const res = await axios.get(this.$store.state.url+'my-posts/'+this.$store.state.user_id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.posts = res 
			}

		},

		created(){
			this.get_posts()
		}

	}
</script>

