<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 



			<div class="row" style="padding-top:40px;">
				<div class="col-md-2"></div>

				<div class="col-md-5">


			<div style="padding-top: 40px; text-align: center;">

				<h1> <i class="bi bi-info-circle color-1"></i> </h1>


				<p>For you to continue using Maifes<br />
				You need to add your phone number</p>
				
				<div>
					
				<form @submit.prevent="add_phone()" method="post">
					
						<div>
							<label>Enter phone number</label>
							<input v-model="phone" type="text" placeholder="eg. 0712345678" class="form-control">
							<button class="btn btn-1 mt-3">Save changes</button>
						</div>

				</form>

				</div>

				
			</div>
						

				</div>



				<div class="col-md-6">
					
				</div>

				
			</div>

	

		

			
		</div> 
	</div> 
	


</template>

<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from 'axios'

	export default{
		name : 'home',
		components : { SideBar, TopBar },
		data(){
			return{
				phone : '',
				
			}
		},

		methods : {

			async add_phone(){
				if(!this.phone){
					return
				}

				const res = await axios.post(this.$store.state.url+'add-phone',{
					phone : this.phone,
					user_id : this.$store.state.user_id 
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				if(res=='success'){
					this.$store.state.phone = this.phone
					localStorage.setItem('phone',this.phone)
					this.$router.push('/home')
				}

			}

		},

		created(){
			
		}

	}
</script>

