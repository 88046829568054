import { createRouter, createWebHistory } from 'vue-router'
import Login from '../components/Login'
import Register from '../components/Register'
import Home from '../components/Home'
import JoinChat from '../components/JoinChat'
import Klub from '../components/Klub'
import CreatePost from '../components/CreatePost'
import Notifications from '../components/Notifications'
import Trending from '../components/Trending'
import SavedTopics from '../components/SavedTopics'
import TopicHistory from '../components/TopicHistory'
import Profile from '../components/Profile'
import ForgotPassword from '../components/ForgotPassword'
import NewPassword from '../components/NewPassword'
import WaitingRoom from '../components/WaitingRoom'
import KlubWaitingRoom from '../components/KlubWaitingRoom'
import Chat from '../components/Chat'
import ViewPost from '../components/ViewPost'
import ViewComment from '../components/ViewComment'
import KlubChat from '../components/KlubChat'
import JoinKlubChat from '../components/JoinKlubChat'
import AddPhone from '../components/AddPhone'
import Tutorials from '../components/Tutorials'


const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },

    {
    path: '/register',
    name: 'register',
    component: Register
  },

    {
    path: '/home',
    name: 'home',
    component: Home
  },

    {
    path: '/join-chat',
    name: 'join-chat',
    component: JoinChat
  },


    {
    path: '/klub',
    name: 'klub',
    component: Klub
  },


    {
    path: '/create-post',
    name: 'create-post',
    component: CreatePost
  },



    {
    path: '/notifications',
    name: 'notifications',
    component: Notifications
  },



    {
    path: '/trending',
    name: 'trending',
    component: Trending
  },



    {
    path: '/saved',
    name: 'saved',
    component: SavedTopics
  },



    {
    path: '/history',
    name: 'history',
    component: TopicHistory
  },


    {
    path: '/profile',
    name: 'profile',
    component: Profile
  },



    {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword
  },



    {
    path: '/new-password/:email',
    name: 'new-password',
    component: NewPassword
  },


    {
    path: '/waiting-room',
    name: 'waiting-room',
    component: WaitingRoom
  },

      {
    path: '/chat',
    name: 'chat',
    component: Chat
  },



      {
    path: '/view-post/:post_id',
    name: 'view-post',
    component: ViewPost
  },



      {
    path: '/view-comment/:id',
    name: 'view-comment',
    component: ViewComment
  },


      {
    path: '/klub-waiting-room',
    name: 'klub-waiting-room',
    component: KlubWaitingRoom
  },


   {
    path: '/klub-chat',
    name: 'klub-chat',
    component: KlubChat
  },


    {
    path: '/join-klub-chat',
    name: 'join-klub-chat',
    component: JoinKlubChat
  },


  {
    path: '/add-phone',
    name: 'add-phone',
    component: AddPhone
  },



  {
    path: '/tutorials',
    name: 'tutorials',
    component: Tutorials
  },

 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
